@import "custom";
// below should be my Sass codes

.leaflet-container {
  // must exist for the mapcontainer
  width: 100%;
  height: 35vh;
}
.react-modal-map {
  height: 39vh;
}
.header {
  text-align: center;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

.hero {
  background-image: url(./img/cover.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 2;
}

.hero::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(21, 20, 51, 0.8);
  z-index: -1;
}

.Background {
  height: calc(
    100vh - 56px
  ); /*56px is the height of navbar, measured from devtool*/
  width: 100vw;
}

a.nav-link.active {
  color: #fff !important;
  background: #262626 !important; // this is must
  text-decoration-line: underline !important;
  text-underline-offset: 0.5em !important; // distance from text
  text-decoration-thickness: 0.2em !important; // line thickness
  text-decoration-color: red !important;
}
a.nav-link:hover {
  color: #fff !important;
  background: #262626 !important; // this is must
  // text-decoration-line: underline !important;
  // text-underline-offset: 0.5em !important; // distance from text
  // text-decoration-thickness: 0.2em !important; // line thickness
  // text-decoration-color: #fff !important;
}
